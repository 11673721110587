<template>
  <div class="contract-edit">
    <entity-modify-header class="mb-10" :title="$t('contracts.edit.edit')" />
    <form-builder v-if="!isLoading" :schema="$options.schema" :initial-data="initialData" @submit="submit">
      <template #footer="{ valid }">
        <v-row class="mt-4">
          <v-col>
            <v-btn color="secondary" block class="primary--text" :to="backRoute">{{ $t('button.back') }}</v-btn>
          </v-col>

          <v-col>
            <v-btn type="submit" color="primary" block :disabled="!valid" :loading="submitLoading">{{
              $t('button.save_contract')
            }}</v-btn>
          </v-col>
        </v-row>
      </template>
    </form-builder>
  </div>
</template>

<script>
import analyticsService from '@/services/analytics';
import usersService from '@/services/users';
import FormBuilder from '@/components/schema/FormBuilder.vue';
import EntityModifyHeader from '@/components/EntityModifyHeader.vue';
import { CONTRACTS } from '@/constants/routes';
import { EDIT_CONTRACT } from '@/constants/analyticsActions';
import notificationService from '@/services/notification';

// Utils
import { createModelData } from '@/schemas/createModelData';

// Schema
import { schema } from '@/schemas/contract.schema';

export default {
  schema,

  name: 'ContractEditPage',

  components: { FormBuilder, EntityModifyHeader },

  props: {
    id: { type: Number, required: true },
    from: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      initialData: createModelData(schema),
      isLoading: false,
      submitLoading: false,
    };
  },

  computed: {
    backRoute() {
      if (this.hasFrom) {
        return this.from;
      }

      return { name: CONTRACTS };
    },

    hasFrom() {
      return Object.keys(this.from).length > 0;
    },
  },

  created() {
    this.isLoading = true;

    usersService
      .getUsageById({ id: this.id })
      .then(usage => {
        this.initialData = {
          ...usage,
          unit: {
            id: usage.unit.parentUnit ? usage.unit.parentUnit : usage.unit.id,
            name: usage.unit.parentUnit ? usage.unit.parentUnitName : usage.unit.name,
          },
          room: usage.unit.parentUnit
            ? {
                id: usage.unit.id,
                name: usage.unit.name,
              }
            : null,
        };

        console.log(this.initialData);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },

  methods: {
    submit(usage) {
      if (this.$options.notificationItem) {
        notificationService.remove(this.$options.notificationItem);
      }

      this.submitLoading = true;
      usersService
        .updateUsageById({ ...usage, clientType: usage.originalClientType }, this.id)
        .then(() => {
          const message = this.$t('contracts.contracts_edited');
          notificationService.success(message, 2000);

          analyticsService.track(EDIT_CONTRACT);
          this.$router.push(this.backRoute);
        })
        .catch(error => {
          this.$options.notificationItem = notificationService.error(
            `${this.$t('error.found_errors')} ${Object.values(error?.response?.data).join(', ')}`
          );
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
  },

  notificationItem: null,
};
</script>

<style lang="scss">
.contract-edit {
  max-width: 548px;
  margin: 0 auto;
}
</style>
